@charset "UTF-8";

// Main Colors
$white:           rgba(255,255,255,1);

// .color-primary-0 { color: #009A47 }	/* Main Primary color */
// .color-primary-1 { color: #3DBB78 }
// .color-primary-2 { color: #19B260 }
// .color-primary-3 { color: #007D3A }
// .color-primary-4 { color: #00602C }

$green-main:      rgba(  0,154, 71,1);
$green-lighter:   rgba( 61,187,120,1);
$green-light:     rgba( 25,178, 96,1);
$green-dark:      rgba(  0,125, 58,1);
$green-darker:    rgba(  0, 96, 44,1);

$black-main:      rgba( 29, 42, 56,1);
$black-lighter:   rgba( 58, 70, 84,1);
$black-light:     rgba( 45, 51, 57,1);
$black-dark:      rgba( 29, 35, 42,1);
$black-darker:    rgba( 23, 24, 26,1);

$grey-main:       rgba(149,165,166,1);
$grey-lighter:    rgba(230,235,240,1);
$grey-light:      rgba(200,205,210,1);
$grey-dark:       rgba(140,150,160,1);
$grey-darker:     rgba(110,130,150,1);

$orange-main:     rgba(243,156, 18,1);
$orange-lighter:  rgba(255,199,109,1);
$orange-light:    rgba(252,181, 68,1);
$orange-dark:     rgba(192,119,  4,1);
$orange-darker:   rgba(151, 93,  0,1);

// Main Fonts
// @import url('https://fonts.googleapis.com/css?family=Lato:400,700|Roboto:400,700|Open+Sans:400,700|Roboto+Condensed:400,700&amp;subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto+Condensed:400,700|Montserrat:500,600&&amp;subset=latin-ext');

// $lato: 'Lato', sans-serif;
$roboto: 'Roboto', sans-serif;
$open-sans: 'Open Sans', sans-serif;
$roboto-condensed: 'Roboto Condensed', sans-serif;
$montserrat: 'Montserrat', sans-serif;

// 1. My custom variables and variable overwrites.

// Fonts
$global-font-family: $open-sans;
$base-heading-font-family: $montserrat;
$navbar-nav-item-font-family: $roboto-condensed;

// Line Height
$base-h1-line-height: 1.3;

// Font Weight
$base-body-font-weight: 400;
$base-heading-font-weight: 500;
$text-bold-font-weight: 900;

// Font Size
$global-font-size: 1rem;
$text-small-font-size: .85rem;
$navbar-nav-item-font-size: 1rem;

// Font Color
$global-color: $black-main;
$global-emphasis-color: $black-dark;
$global-inverse-color: $white;
$inverse-global-color: rgba($global-inverse-color, 0.9);

$article-meta-color: $black-lighter;

// Heading
$base-heading-color: $black-light;

// Base Link
$base-link-hover-text-decoration: none;

// Navbar
$navbar-nav-item-height: 100px;
$navbar-item-color: $black-main;
$navbar-nav-item-color: $black-main;
$navbar-nav-item-hover-color: $green-dark;
$navbar-nav-item-active-color: $green-darker;
$navbar-nav-item-onclick-color: $green-dark;

$inverse-navbar-nav-item-color: rgba($white, .9);
$inverse-navbar-nav-item-hover-color: rgba($white, 1);
$navbar-nav-item-padding-horizontal: 20px;

$navbar-dropdown-width: 160;
$navbar-dropdown-nav-item-color: $black-lighter;
$navbar-dropdown-nav-item-hover-color: $green-darker;
$navbar-dropdown-nav-item-active-color: $green-darker;

$dropdown-min-width: 160px;
$dropdown-padding: 15px 20px;
$dropdown-nav-item-color: $black-main;
$dropdown-nav-item-hover-color: $green-darker;
$dropdown-nav-item-active-color: $green-darker;
$dropdown-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.2);

// Nav
$nav-header-font-size: 1rem;

// Subnav
$subnav-margin-horizontal: 10px;

// Button
$button-default-color: $grey-lighter;
$button-default-border: $black-dark;
$button-default-background: $black-dark;
$button-default-hover-color: $grey-lighter;
$button-default-hover-border: $black-darker;
$button-default-hover-background: $black-dark;

$button-primary-background: $grey-darker;
$button-primary-hover-background: $grey-dark;

$button-secondary-background: $green-darker;
$button-secondary-hover-background: $green-dark;

$button-link-hover-text-decoration: none;

// Badge
$badge-background: $black-lighter;

// Height
$height-small-height: 150px;
$height-medium-height: 300px;
$height-large-height: 450px;
$height-xlarge-height: 600px;
$height-xxlarge-height: 800px;

// Background
$global-muted-background: rgba($grey-lighter, .4);
$global-primary-background: $green-lighter;
$global-secondary-background: $green-darker;

// Overlay
$overlay-default-background: rgba($black-darker, 0.6);
$overlay-primary-background: rgba($global-primary-background, 0.6);
$overlay-secondary-background: rgba($global-secondary-background, 0.6);

// Modal
$modal-background: rgba($black-darker, 0.6);

// Card
$card-default-header-border: $grey-lighter;
$card-default-footer-border: $grey-lighter;

// List
$list-divider-border: $grey-lighter;

// Off-canvas
$offcanvas-bar-background: $white;
$offcanvas-overlay-background: rgba($black-dark, 0.8);

// Main Border
$global-border: $grey-lighter;
$border-rounded-border-radius: 6px;

// Alternative Border Radius
$border-rounded-border-radius-medium: 12px;
$border-rounded-border-radius-large: 24px;
$border-rounded-border-radius-xlarge: 48px;

// 2. Import default variables and available mixins.

@import "variables-theme.scss";
@import "mixins-theme.scss";

// 3. My custom mixin overwrites.

// $inverse-global-color-mode: none;
// Card
// $card-primary-color-mode: none;
// $card-secondary-color-mode: none;

// Navbar
// $navbar-color-mode: none;

// Off-canvas
$offcanvas-bar-color-mode: none;

// Overlay
$overlay-primary-color-mode: none;

// Section
// $section-primary-color-mode: none;
// $section-secondary-color-mode: none;

// Tile
// $tile-primary-color-mode: none;
// $tile-secondary-color-mode: none;

// 4. Import UIkit.

@import "uikit-theme.scss";

// My Custom CSS
// Logo
.uk-logo img {
  width: 260px;
}

#home {
  border-bottom: 1px solid #efefef;
}

// Slideshow
#navbar.uk-sticky:not(.uk-sticky-fixed) {
  border-bottom: 1px solid rgba(255,255,255,.2)
}
// #navbar.uk-sticky:not(.uk-sticky-fixed) .uk-navbar-right {
//   border-right: 1px solid rgba(255,255,255,.2)
// }
#navbar.uk-sticky:not(.uk-sticky-fixed) .uk-navbar-right .uk-navbar-nav {
  border-right: 1px solid rgba(255,255,255,.2)
}

// Alternative Backgrounds
// Alternative Background Colors
.uk-section-primary-light,
.uk-background-primary-light {
  background-color: rgba($global-primary-background, 0.1);
}
.uk-section-primary-lighter,
.uk-background-primary-lighter {
  background-color: rgba($global-primary-background, 0.05);
}
.uk-section-secondary-light,
.uk-background-secondary-light {
  background-color: rgba($global-secondary-background, 0.2);
}
.uk-section-secondary-lighter,
.uk-background-secondary-lighter {
  background-color: rgba($global-secondary-background, 0.1);
}

// Alternative Paddings
.uk-padding-xsmall {
  padding: 10px;
}
// Alternative Text Colors
.uk-text-white {
  color: $white!important;
}

// Border
.uk-border-rounded-medium {
  border-radius: $border-rounded-border-radius-medium;
}
.uk-border-rounded-large {
  border-radius: $border-rounded-border-radius-large;
}
.uk-border-rounded-xlarge {
  border-radius: $border-rounded-border-radius-xlarge;
}
.uk-border-rounded-reset-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.uk-border-rounded-reset-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

// Cover
.uk-cover {
  transform: translate(-50%,-50%)!important;
}

// Height
.uk-height-80vh {
  height: 80vh;
}
.uk-height-70vh {
  height: 70vh;
}
.uk-height-60vh {
  height: 60vh;
}
.uk-height-xlarge {
  height: $height-xlarge-height;
}
.uk-height-xxlarge {
  height: $height-xxlarge-height;
}

/* Form */
.uk-input.uk-border-rounded-xlarge {
  text-indent: 10px;
}

// Shadow
.uk-box-shadow-none {
  box-shadow: none;
}

/* Responsive Videos */
#video-wrapeper {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
#video-wrapeper iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
